"use client";

import { AssetFragment, TemplateIntegrationListingFragment } from "contentful/gql/graphql";
import { useState } from "react";
import IntegrationsSection from "./IntegrationsSection";

type ResourcesPageJSON = {
  integrations: {
    cta: string;
    headline: string;
    subheader: string;
  };
  widgets: {
    cta: string;
    headline: string;
    subheader: string;
  };
};

export default function ResourcesPage({
  json,
  integrations,
}: {
  json: ResourcesPageJSON;
  assets: (AssetFragment | undefined)[] | undefined;
  integrations: (TemplateIntegrationListingFragment | null)[];
}) {
  const [integrationsPage, setIntegrationsPage] = useState(1);
  const itemsPerPage = 6;

  return (
    <div className="py-lg">
      <IntegrationsSection
        integrations={integrations}
        integrationsPage={integrationsPage}
        setIntegrationsPage={setIntegrationsPage}
        itemsPerPage={itemsPerPage}
        json={json}
      />
    </div>
  );
}
