"use client";

import { useEffect, useState } from "react";
import { AssetFragment } from "contentful/gql/graphql";
import Image from "next/image";
import HeadingText from "components/ComponentHeading/HeadingText";
import CtaLink from "components/CtaLink";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

type ResourceSection = {
  resource_image: {
    imageAssetReferenceId: string;
    alt: string;
    categoryCaption: string;
  };
};

type FeaturedSection = {
  cta: {
    text: string;
    link: string;
  };
  headline: string;
  description: string;
  resource_section: ResourceSection;
};

type ResourcesFeaturedResourceJSON = {
  featured_section: FeaturedSection[];
};

export default function ResourcesFeaturedResource({
  json,
  assets,
}: {
  json: ResourcesFeaturedResourceJSON;
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div className={`transition-opacity duration-700 ${isLoaded ? "opacity-100" : "opacity-0"}`}>
      {json.featured_section.length === 1 ? (
        // Single section without slider
        <div>{renderSection(json.featured_section[0], assets, 0)}</div>
      ) : (
        // Multiple sections with slider
        <div
          className={`
          [&_.swiper-button-next]:!top-1/3 
          [&_.swiper-button-next]:!-translate-y-1/2 [&_.swiper-button-next]:!transform
          [&_.swiper-button-next]:!text-primary-neutral-50 [&_.swiper-button-prev]:!top-1/3
          [&_.swiper-button-prev]:!-translate-y-1/2 [&_.swiper-button-prev]:!transform
          [&_.swiper-button-prev]:!text-primary-neutral-50 [&_.swiper-pagination-bullet]:!bg-primary-neutral-50
          [&_.swiper-pagination]:!bottom-[-5px]
          [&_.swiper]:!overflow-visible
        `}
        >
          <Swiper
            modules={[Navigation, Pagination, EffectFade]}
            navigation
            pagination={{ clickable: true }}
            effect="fade"
            fadeEffect={{ crossFade: true }}
            speed={800}
            style={{ overflow: "visible" }}
          >
            {json.featured_section.map((section, index) => (
              <SwiperSlide key={index} style={{ overflow: "visible" }}>
                {renderSection(section, assets, index)}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
}

// Helper function to render a section
function renderSection(section: FeaturedSection, assets: (AssetFragment | undefined)[] | undefined, index: number) {
  const resourceImage = assets?.find(
    (asset) => asset?.sys.id === section.resource_section.resource_image.imageAssetReferenceId,
  );

  return (
    <>
      <section className="relative">
        {resourceImage && (
          <>
            <Image
              width={resourceImage.width ?? 0}
              height={resourceImage.height ?? 0}
              src={resourceImage.url ?? ""}
              alt={resourceImage.title ?? ""}
              className="-mt-2xl mb-xl w-full rounded-3xl object-cover"
            />
            <span className="absolute right-4 top-4 rounded-full bg-primary-neutral-50/20 px-3 py-1 text-sm text-primary-neutral-50">
              {section.resource_section.resource_image.categoryCaption}
            </span>
          </>
        )}
      </section>
      <section className="mx-auto mb-md max-w-3xl text-center">
        <HeadingText heading={section.headline} headingType="H2" textAlign="center" />
        <p className="text-gray-600 mx-auto mb-md max-w-3xl text-center text-lg">{section.description}</p>
        <CtaLink label={section.cta.text} link={section.cta.link} hierarchy="Orange Fill" size="lg" />
      </section>
    </>
  );
}
