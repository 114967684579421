"use client";

import ColorizedText from "components/ColorizedText";
import CtaLink from "components/CtaLinkV2";
import typography from "components/typography";
import { assetFragment, componentLogoCloudFragment, ctaFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { ComponentConversionPanelFragment } from "contentful/gql/graphql";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Image from "next/image";
import { useLayoutEffect, useRef } from "react";
import { cn } from "utils/functions";
import richTextParser, { RichText } from "utils/richTextParser";
import styles from "./ConversionPanelV2.module.css";
import { cva } from "class-variance-authority";
import LogoBar from "./LogoBar";

type HeadingTypes = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const headingSize = {
  xs: "text-xl",
  sm: "text-2xl",
  md: "text-3xl",
  lg: "text-4xl",
  xl: "text-5xl",
  xxl: "text-6xl",
} as const;

type headingSizeTypes = keyof typeof headingSize;

type Variation = "light" | "dark" | "simple" | "compact" | undefined;

const ctaLinkVariantClasses = cva(["rounded transition-colors"], {
  variants: {
    intent: {
      primary:
        "border bg-primary-neutral-50 text-tertiary-pink-500 hover:bg-primary-neutral-50/20 hover:text-primary-neutral-50",
      secondary: "border border-primary-neutral-50 text-primary-neutral-50 hover:bg-primary-neutral-50/20",
      primaryDark:
        "border bg-primary-neutral-50 hover:bg-primary-neutral-50/20 text-tertiary-pink-500 border-primary-neutral-50 hover:border-primary-neutral-50 hover:text-primary-neutral-50",
      secondaryDark:
        "text-primary-neutral-50 border border-primary-neutral-50 hover:bg-primary-neutral-50/20 hover:border-primary-neutral-50 hover:text-primary-neutral-50",
    },
  },
});

const ConversionPanelV3 = ({
  variation,
  heading,
  headingType,
  callToActionCollection,
  body,
  logos,
  accentImage: accentImageProp,
}: ComponentConversionPanelFragment) => {
  const accentImage = getFragmentData(assetFragment, accentImageProp);
  variation = variation?.toLowerCase() as Variation;
  const logoBar = getFragmentData(componentLogoCloudFragment, logos);

  const sectionRef = useRef<any>(null);
  const tl = useRef<any>(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.registerPlugin(ScrollTrigger);
      tl.current = gsap
        .timeline({
          paused: true,
        })
        .to(sectionRef.current, {
          y: 0,
          opacity: 1,
          duration: 1,
        });

      ScrollTrigger.create({
        trigger: sectionRef.current,
        start: "top 100% +=25%",
        onEnter: () => tl.current?.play(),
      });

      ScrollTrigger.create({
        trigger: sectionRef.current,
        start: "top-=25% 100%",
        onLeaveBack: () => tl.current?.pause(0),
      });
    }, sectionRef.current);
  }, []);

  return (
    <div
      className={cn(`dark relative flex translate-y-1/4 flex-col overflow-hidden rounded-lg opacity-0 md:flex-row`)}
      ref={sectionRef}
    >
      <div
        className={cn(
          "flex flex-col gap-sm p-md text-primary-neutral-50 md:flex-row 2xl:p-lg",
          accentImage?.url ? "md:w-3/5" : "w-full",
          body ? "flex-col justify-center" : "items-center justify-between",
        )}
      >
        <div className={cn(!body ? "flex" : "")}>
          {heading ? (
            headingType == "H2" ? (
              <h2 className={cn(typography.heading.sectionHeading, "mb-2xs")}>
                <ColorizedText heading={heading} />
              </h2>
            ) : (
              <h3 className={cn(typography.heading.componentHeading, "mb-2xs")}>
                <ColorizedText heading={heading} />
              </h3>
            )
          ) : null}
          {body && (
            <div className={cn(typography.body.componentBody, "text-primary-dark-50")}>
              {richTextParser(body as RichText)}
            </div>
          )}
        </div>
        {callToActionCollection?.items || logoBar ? (
          <div className={cn("flex flex-col gap-sm sm:flex-row", body ? "mt-md" : "")}>
            {callToActionCollection?.items &&
              callToActionCollection.items.map((item, index) => {
                const callToAction = getFragmentData(ctaFragment, item);
                return (
                  callToAction && (
                    <CtaLink
                      {...callToAction}
                      key={callToAction.sys.id}
                      extendClassNames={ctaLinkVariantClasses({
                        intent:
                          variation === "dark"
                            ? index === 0
                              ? "primaryDark"
                              : "secondaryDark"
                            : index === 0
                              ? "primary"
                              : "secondary",
                      })}
                      textAlign="center"
                      additionalType="CTA"
                    />
                  )
                );
              })}
            {logoBar && (
              <div className="mt-sm sm:ml-auto sm:mt-0">
                <LogoBar {...logoBar} />
              </div>
            )}
          </div>
        ) : null}
      </div>
      {!logoBar && accentImage?.url && (
        <Image
          src={accentImage.url}
          alt={accentImage.title || "Image"}
          width={accentImage.width || 832}
          height={accentImage.height || 520}
          sizes="25vw"
          className={cn(
            "-mb-sm -mr-sm ml-auto mt-auto hidden max-h-96 object-contain object-right-bottom md:block md:w-2/5 md:pt-md",
            accentImage.title?.toLowerCase().includes("globe") && "opacity-70 mix-blend-screen",
          )}
        />
      )}
      <div
        className={cn(
          "absolute -z-10 h-full w-full",
          variation === "light" ? styles.noiseBgLight : variation === "dark" ? styles.noiseBgDark : styles.noiseBgLight,
        )}
      ></div>
      <div className={cn("absolute -z-10 h-full w-full", variation === "dark" ? styles.wavesBg : "")}></div>
    </div>
  );
};

export default ConversionPanelV3;
