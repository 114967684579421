import HeadingText from "components/ComponentHeading/HeadingText";
import { TemplateIntegrationListingFragment } from "contentful/gql/graphql";
import { cn } from "utils/functions";
import typography from "components/typography";
import Image from "next/image";
import Link from "next/link";
import { getFragmentData } from "contentful/gql";
import { assetFragment, metaSeoFragment } from "contentful/fragments";

type Props = {
  integrations: Array<TemplateIntegrationListingFragment | null>;
  integrationsPage: number;
  setIntegrationsPage: (page: number) => void;
  itemsPerPage: number;
  json: {
    integrations: {
      cta: string;
      headline: string;
      subheader: string;
    };
  };
};

export default function IntegrationsSection({
  integrations,
  integrationsPage,
  setIntegrationsPage,
  itemsPerPage,
  json,
}: Props) {
  return (
    <section id="integrations" className="mx-auto text-center">
      <HeadingText heading={json.integrations.headline} headingType="H2" textAlign="center" />
      <p className={cn(typography.body.md, "mx-auto mb-2xl mt-xs max-w-3xl text-center text-primary-dark-800/70")}>
        {json.integrations.subheader}
      </p>

      <div className="mx-auto mb-md grid gap-xs text-left md:grid-cols-2 lg:grid-cols-3">
        {integrations?.slice(0, integrationsPage * itemsPerPage).map(
          (integration) =>
            integration && (
              <Link
                key={integration?.sys.id}
                className="group relative h-full overflow-hidden rounded-xl bg-secondary-light-100/60 pt-sm transition-all duration-300 hover:shadow-lg dark:bg-primary-dark-700"
                href={integration.externalLink || `/integration/${integration.slug}`}
              >
                <div className="flex items-center justify-between overflow-hidden">
                  <span
                    className={cn(
                      typography.body.xs,
                      "absolute left-2 top-2 z-10 inline-block rounded-full bg-primary-light-650 px-xs py-4xs text-xs font-medium text-primary-neutral-50 opacity-80 backdrop-blur-sm md:text-sm",
                    )}
                  >
                    <span className="opacity-100">Integration</span>
                  </span>
                  <div className="flex h-[100px] w-full items-center justify-center">
                    {integration.integrationImage &&
                      (() => {
                        const image = getFragmentData(assetFragment, integration.integrationImage);
                        return (
                          <Image
                            src={image?.url ?? ""}
                            height={image?.height ?? 0}
                            width={image?.width ?? 0}
                            alt={image?.title ?? "Integration image"}
                            className="mx-auto block h-full max-h-[170px] w-full max-w-[90px] scale-110 rounded-xl object-contain brightness-90 filter transition-all duration-300 group-hover:scale-125 group-hover:brightness-100"
                          />
                        );
                      })()}
                  </div>
                </div>
                <div className="p-sm">
                  <h2 className="pb-2 text-lg font-bold text-primary-dark-800 group-hover:underline dark:text-primary-neutral-50">
                    {integration.title}
                  </h2>
                </div>
              </Link>
            ),
        )}
      </div>

      {integrationsPage * itemsPerPage < (integrations?.length || 0) && (
        <button
          onClick={(e) => {
            e.preventDefault();
            setIntegrationsPage(integrationsPage + 1);
          }}
          className="mx-auto mt-md rounded border border-primary-light-500 bg-primary-light-500 px-sm py-2xs font-medium text-primary-neutral-50 transition-colors hover:bg-primary-light-500/20 hover:text-primary-light-500"
        >
          {json.integrations.cta}
        </button>
      )}
    </section>
  );
}
