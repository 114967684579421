"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import Image from "next/image";
import { TemplateEventFragment, TemplateEventListingFragment } from "contentful/gql/graphql";
import { cn } from "utils/functions";
import "swiper/css";
import "swiper/css/navigation";
import Link from "next/link";
import { getFragmentData } from "contentful/gql";
import { assetFragment, metaSeoFragment } from "contentful/fragments";

interface EventCarouselProps {
  events: (TemplateEventFragment | undefined)[];
}

export default function EventCarousel({ events }: EventCarouselProps) {
  return (
    <div className="relative mx-auto w-full max-w-6xl text-left">
      <Swiper
        modules={[Navigation]}
        navigation
        slidesPerView={2}
        spaceBetween={16}
        loop={true}
        className="event-swiper"
        breakpoints={{
            768: {
                slidesPerView: 1,
            },
            992: {
                slidesPerView: 2,
            },
        }}
      >
        {events.map((event) => {
          const seoData = getFragmentData(metaSeoFragment, event?.metaSeo);
          return (
          <SwiperSlide key={event?.sys.id}>
            <Link href={`/event/${event?.slug}`}>
              <div className="w-full flex flex-row gap-sm border border-primary-dark-100 rounded-lg">
              <div className="relative flex-1 aspect-video overflow-hidden rounded-lg">
                {seoData && (() => {
                  const image = getFragmentData(assetFragment, seoData.openGraphImage);
                  return (
                    <Image
                      src={image?.url ?? ""}
                      alt={image?.title ?? ""}
                      fill
                      className="object-cover"
                      />
                    );
                  })()}
                </div>
                <div className="flex-1 p-4 min-h-[200px]">
               <h3 className="text-gray-900 mt-2 text-lg font-semibold line-clamp-2">{seoData?.pageTitle}</h3>
               <p className="text-primary-dark-500 mt-2 text-sm font-medium line-clamp-3">{seoData?.pageDescription}</p>
               </div>
              </div>
            </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
