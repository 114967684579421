"use client";

import typography from "components/typography";
import { analytics } from "lib/segment";
import { useSearchParams } from "next/navigation";
import Script from "next/script";
import { useEffect, useRef, useState } from "react";
import { segmentTrackBooking } from "utils/analytics";
import { cn } from "utils/functions";
import { isValidEmail } from "utils/emailValidation";
import { mixpanelAnalytics as mixpanel } from "lib/mixpanel";

const EnterpriseLeadFormMinimal = () => {
  const [country, setCountry] = useState<string>("");
  const [countryRegion, setCountryRegion] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [info, setInfo] = useState<string>("");
  const [company, setCompany] = useState<string>("");

  const validEmail = isValidEmail(email);

  const searchParams = useSearchParams();

  const formDataRef = useRef({
    form: "Enterprise Contact Form",
    first_name: firstName,
    last_name: lastName,
    work_email: email,
    company_name: company,
    info: info,
    country: country,
    countryRegion: countryRegion,
    lead_source: searchParams.get("lead_source"),
    utm_source: searchParams.get("utm_source"),
    utm_medium: searchParams.get("utm_medium"),
    utm_campaign: searchParams.get("utm_campaign"),
  });

  useEffect(() => {
    fetch("/api/get-geo-and-ip")
      .then((response) => response.json())
      .then((data: any) => {
        setCountry(data.geo.country?.toUpperCase());
        setCountryRegion(data.geo.region?.toUpperCase());
      });
  }, []);

  function trackBookingActionsHandler(event: MessageEvent) {
    segmentTrackBooking(event.data["action"], formDataRef.current);
  }

  useEffect(() => {
    window.addEventListener("message", trackBookingActionsHandler, false);

    return () => {
      window.removeEventListener("message", trackBookingActionsHandler, false);
    };
  }, []);

  return (
    <div className="dark rounded-lg bg-primary-dark-50 p-sm text-primary-dark-900 xl:p-6">
      <Script id="script-chilipiper-src" src="https://js.chilipiper.com/marketing.js" />
      <Script id="script-chilipiper-action">
        {`function q(a){return function(){ChiliPiper[a].q=(ChiliPiper[a].q||[]).concat([arguments])}}window.ChiliPiper=window.ChiliPiper||"submit scheduling showCalendar submit widget bookMeeting".split(" ").reduce(function(a,b){a[b]=q(b);return a},{});ChiliPiper.scheduling("appsmith", "inbound_router", {title: "Thanks! What time works best for a quick call?"})`}
      </Script>

      <h2 className={typography.heading.secondaryComponentHeading}>Book a meeting to get started.</h2>
      <form
        name="why-appsmith-enterprise-book-meeting-form"
        id="why-appsmith-enterprise-book-meeting-form"
        data-name="Why Appsmith Enterprise Book a Meeting Form"
        action="#"
        method="get"
        aria-label="Appsmith Enterprise - Book a meeting with our team"
        className="mt-sm grid scroll-mt-[240px] grid-cols-1 gap-sm text-sm sm:grid-cols-2 xl:mt-md "
        onSubmit={(e) => {
          analytics?.identify(email, { name: `${firstName} ${lastName}`, email: email });
          analytics?.alias(email);
          analytics?.track("Submitted Form", { ...formDataRef.current, userId: email });

          mixpanel?.identify(email);
          mixpanel?.alias(email);
          mixpanel?.people.set_once({ first_conversion_name: formDataRef.current.form });
        }}
      >
        <div>
          <label htmlFor="first-name">
            First name <span className="font-light text-tertiary-red-500 opacity-60">*</span>
          </label>
          <div className="mt-4xs">
            <input
              type="text"
              maxLength={256}
              name="Firstname"
              id="Firstname"
              value={firstName}
              onChange={(e) => {
                formDataRef.current.first_name = e.target.value;
                setFirstName(e.target.value);
              }}
              required
              autoComplete="given-name"
              className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
            />
          </div>
        </div>
        <div>
          <label htmlFor="last-name">
            Last name <span className="font-light text-tertiary-red-500 opacity-60">*</span>
          </label>
          <div className="mt-4xs">
            <input
              type="text"
              name="Lastname"
              id="Lastname"
              value={lastName}
              onChange={(e) => {
                formDataRef.current.last_name = e.target.value;
                setLastName(e.target.value);
              }}
              required
              autoComplete="family-name"
              className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="email">
            Work email <span className="font-light text-tertiary-red-500 opacity-60">*</span>
          </label>
          <div className="mt-4xs">
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => {
                formDataRef.current.work_email = e.target.value;
                setEmail(e.target.value);
              }}
              onBlur={() => {
                if (validEmail) {
                  analytics?.identify(email, { email: email });
                }
              }}
              required
              autoComplete="email"
              className={cn(
                "block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]",
                !validEmail ? "border-tertiary-red-500" : "",
              )}
            />
            {!validEmail ? (
              <p className="mt-[5px] text-sm text-tertiary-red-500">Please enter a valid work email address.</p>
            ) : null}
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="company_name">
            Company <span className="font-light text-tertiary-red-500 opacity-60">*</span>
          </label>
          <div className="mt-4xs">
            <input
              type="text"
              name="company_name"
              id="company_name"
              value={company}
              onChange={(e) => {
                formDataRef.current.company_name = e.target.value;
                setCompany(e.target.value);
              }}
              required
              autoComplete="organization"
              className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="flex items-end justify-between">
            <label htmlFor="Info">Tell us a little bit about your project.</label>
            <span id="Info-description" className="hidden text-2xs opacity-80 sm:inline-block sm:text-xs">
              Max. 500 characters
            </span>
          </div>
          <div className="mt-4xs">
            <textarea
              id="Info"
              name="Info"
              aria-describedby="Info"
              rows={3}
              className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
              value={info}
              onChange={(e) => {
                formDataRef.current.info = e.target.value;
                setInfo(e.target.value);
              }}
            />
            <span id="Info-description" className="inline-block text-xs sm:hidden sm:text-sm">
              Max. 500 characters
            </span>
          </div>
        </div>
        <div className="sm:col-span-2">
          <button
            type="submit"
            className={cn(
              "group relative flex w-full items-center justify-center whitespace-nowrap px-sm py-xs font-medium leading-none",
              "rounded text-primary-neutral-50 transition hover:text-primary-light-500 dark:hover:text-primary-neutral-50",
            )}
          >
            <span className="absolute left-0 top-0 h-full w-full rounded border-[1px] border-primary-light-500 bg-gradient-to-br from-primary-light-500/10 to-primary-light-650/10 opacity-0 transition-opacity duration-300 group-hover:opacity-100 dark:from-primary-light-500/50 dark:to-primary-light-650/50"></span>
            <span className="absolute left-0 top-0 h-full w-full rounded bg-gradient-to-br from-primary-light-500 to-primary-light-650  transition-opacity duration-300 group-hover:opacity-0"></span>
            <span className="relative">Book a meeting</span>
          </button>
          <input
            type="hidden"
            name="lead_source"
            id="lead_source"
            value={formDataRef.current.lead_source ?? "Website"}
          />
          <input type="hidden" name="country" value={country} />
          <input type="hidden" name="countryRegion" value={countryRegion} />
          <input type="hidden" name="utm_source" value={formDataRef.current.utm_source ?? "unknown"} />
          <input type="hidden" name="utm_medium" value={formDataRef.current.utm_medium ?? "unknown"} />
          <input type="hidden" name="utm_campaign" value={formDataRef.current.utm_campaign ?? "unknown"} />
        </div>
      </form>
      <div className="success-message hidden">
        <div>Thank you! Your submission has been received!</div>
      </div>
      <div className="hidden">
        <div>Oops! Something went wrong while submitting the form.</div>
      </div>
      <div aria-hidden="true" id="required-description" className="mt-2xs text-xs font-light opacity-60">
        <span className="font-light text-tertiary-red-500 opacity-60">*</span> Required
      </div>
    </div>
  );
};

export default EnterpriseLeadFormMinimal;
